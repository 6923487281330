$grid-container-max: 1920px;
$grid-container-min: 1024px;
$grid-container-gutter: 6%;
$grid-columns: 12;
$grid-gutter: 2%;

.container {
  max-width: $grid-container-max;
  min-width: $grid-container-min;
  padding-right: $grid-container-gutter;
  padding-left: $grid-container-gutter;
}

.row {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);
  grid-column-gap: $grid-gutter;

  // span the full width of the grid if a column class is not assigned
  > :not([class*="col-"]) {
    grid-column: span $grid-columns;
  }
}

@for $x from 1 through $grid-columns {
  .col-#{$x} {
    grid-column: span #{$x};

    @for $y from 1 to $grid-columns {
      $grid-column-start: ($y);
      $grid-column-total: ($x + $y - 1);

      @if $grid-column-total <= $grid-columns {
        &.col-start-#{$y} {
          grid-column: $grid-column-start / span #{$x};
        }
      }
    }
  }
}
