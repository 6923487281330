.btn-list {
  font-size: 0;

  &.is-right {
    float: right;
  }

  > .btn + .btn {
    margin-left: 12px;
  }
}

.btn {
  position: relative;
  display: inline-block;
  padding: 11px 30px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: $border-radius;
  box-sizing: border-box;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  user-select: none;

  &.is-disabled {
    color: $btn-disabled-color;
    pointer-events: none;
    cursor: default;
    background-color: $btn-disabled-bg;
    border-color: $btn-disabled-border;

    &.btn-secondary,
    &.btn-danger {
      color: $btn-disabled-secondary-color;
      background-color: $btn-disabled-secondary-bg;
      border-color: $btn-disabled-secondary-border;
    }
  }

  &.has-icon,
  &.btn-with-icon,
  &.has-badge {
    padding-right: 18px;
    padding-left: 18px;
  }

  &.is-loading {
    pointer-events: none;
  }

  .sg-icon,
  .sg-loader,
  .badge {
    display: block;
    float: left;
    margin-right: 6px;
  }

  .badge {
    margin-bottom: 0;
  }
}

.btn-primary {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;

  &:hover,
  &:focus {
    color: $btn-primary-color;
    background-color: $btn-primary-bg-hover;
  }

  &:active,
  &.is-active {
    @include btn-shadow;
  }

  &.has-badge .badge {
    color: $btn-primary-badge-color;
    background-color: $btn-primary-badge-bg;
  }
}

.btn-secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-border;

  &:hover,
  &:focus {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg-hover;
  }

  &:active,
  &.is-active {
    @include btn-shadow;
  }

  &.has-badge .badge {
    color: $btn-secondary-badge-color;
    background-color: $btn-secondary-badge-bg;
  }
}

.btn-tertiary {
  color: $btn-tertiary-color;
  background-color: $btn-tertiary-bg;
  border-color: $btn-tertiary-bg;

  &:hover,
  &:focus {
    color: $btn-tertiary-color-hover;
  }

  &.has-badge .badge {
    background-color: $btn-tertiary-badge-bg;
  }
}

.btn-danger {
  color: $btn-danger-color;
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;

  &:hover,
  &:focus {
    color: $btn-danger-color;
    background-color: $btn-danger-bg-hover;
  }

  &:active,
  &.is-active {
    @include btn-shadow;
    color: $btn-danger-color;
  }

  &.has-badge .badge {
    color: $btn-danger-badge-color;
    background-color: $btn-danger-badge-bg;
  }
}

.btn-small {
  padding: 6px 18px;
}

.btn-dropdown {
  padding-right: 30px;
  padding-left: 18px;

  &::before {
    @include icon-font-base;
    position: absolute;
    top: calc(50% - 8px);
    right: 12px;
    color: inherit;
    content: $icon-caret;
  }
}

.btn-dropdown-icon,
.btn-dropdown-gear {
  padding-right: 27px;
  padding-left: 0;
  border: 0;

  &.btn-primary::before,
  &.btn-primary > .sg-icon {
    color: $white;
  }

  &::before,
  > .sg-icon {
    color: $slate-60;
  }

  &.rotate-icon {
    &.is-active > .sg-icon {
      transform: rotate(135deg);
    }
  }

  > .sg-icon {
    display: block;
    font-size: 18px;
    transition: transform 0.5s;
  }
}

.btn-save {
  position: relative;
  width: 90px; // reconsider this fixed width before localization
  padding-right: 0;
  padding-left: 0;

  &.is-loading {
    .sg-loader {
      opacity: 1;
    }

    .btn-save-label {
      opacity: 0;
    }
  }

  &.is-saved {
    .sg-icon-check {
      opacity: 1;
    }

    .btn-save-label,
    .sg-loader {
      opacity: 0;
    }
  }

  .btn-save-label,
  .sg-loader,
  .sg-icon-check {
    transition: opacity 0.3s;
  }

  .sg-loader,
  .sg-icon-check {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    float: none;
    margin: 0 auto;
    opacity: 0;
  }
}

.btn-on-dark.btn-secondary {
  color: $btn-dark-color;
  background-color: $btn-dark-bg;
  border-color: $btn-dark-border;

  &:hover,
  &:focus {
    background-color: $btn-dark-bg-hover;
    border-color: $btn-dark-color;
  }

  &.is-disabled {
    color: $btn-disabled-secondary-color;
    background-color: $btn-dark-bg;
    border-color: $btn-disabled-secondary-border;

    &:hover,
    &:focus {
      background-color: inherit;
    }
  }

  &.has-badge .badge {
    color: $btn-dark-badge-color;
    background-color: $btn-dark-badge-bg;
  }
}

.btn-group {
  display: inline-block;
  padding: 4px;
  border: 1px solid $sg-blue-80;
  border-radius: $border-radius;

  &.is-disabled {
    border-color: $btn-disabled-border;

    > .btn-group-item {
      color: $btn-disabled-secondary-color;
      pointer-events: none;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    > .btn-group-item.is-active {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
      border-color: $btn-disabled-border;
    }
  }

  > .btn-group-item {
    float: left;
    padding: 6px 18px;
    color: $btn-group-color;
    background-color: $btn-group-bg;
    border: 1px solid transparent;

    &:hover,
    &:focus {
      background-color: $btn-group-bg-hover;
    }

    &.is-active {
      color: $btn-group-color-active;
      background-color: $btn-group-bg-active;
      border-color: $btn-group-border-active;
    }
  }

  > .btn-group-item.btn-small {
    padding: 2px 12px;
  }
}

.btn-group-stacked {
  .btn-group-item {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}

.btn-selectable {
  padding: 18px;
  color: $btn-selectable-color;
  text-align: left;
  border-color: $btn-selectable-border;

  &:hover {
    border-color: $btn-selectable-border-hover;
  }

  &.is-active {
    border-color: $btn-selectable-border-active;
  }

  &.has-icon,
  &.btn-with-icon {
    .sg-icon,
    img {
      position: absolute;
      top: 50%;
      left: 51px;
      width: 25px;
      height: 25px;
      margin-top: -13px;
      font-size: 25px;
    }

    .input-checkbox-label,
    .input-radio-label,
    .btn-content {
      margin-left: 76px;
    }
  }

  .input-checkbox-label,
  .input-radio-label,
  .btn-content {
    margin-left: 33px;
  }

  .input-checkbox-label,
  .input-radio-label {
    position: static;
    font-weight: bold;
    color: $btn-selectable-color;

    &::before {
      position: absolute;
      top: 50%;
      left: 18px;
      margin-top: -8px;
      margin-right: 0;
      margin-left: 0;
    }
  }

  [type="checkbox"]:checked + .input-checkbox-label::after,
  [type="radio"]:checked + .input-radio-label::after {
    position: absolute;
    top: 50%;
    left: 23px;
    margin-top: -3px;
    margin-left: 0;
  }
}
