body,
html {
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
}
div.login-container {
  background: inherit;
  .login-btn {
    .login-sso-btn {
      font-weight: 100;
      padding-right: 30px;
      padding-left: 30px;
      margin: 0;
    }
  }
}

.input-text-wrap.is-disabled input {
  color: #9e9e9ee0;
}

.sso-link {
  font-size: 13px;
  text-align: center;
}

.sso-login-message {
  font-size: 13px;
  margin-top: 10px;
}

.spinner-border {
  top: 50%;
  position: fixed;
  margin-top: -1rem;
}

@import "./_mixins.scss";
@import "./_reset.scss";
@import "./variables.scss";

@import "./grid.scss";
@import "./main.scss";
@import "./login_typography";
@import "./utilities.scss";

@import "./_login.scss";
@import "./loader.scss";
@import "./alerts.scss";
@import "./text-inputs.scss";
@import "./buttons.scss";
@import "./badges.scss";
