// sass-lint:disable no-important, no-color-literals
// linting properties disabled due to potential for alert position to be overriden

.alert {
  position: relative;
  z-index: 1000;
  padding: 20px 0;
  font-size: 13px;
  text-align: center;

  &.alert-success {
    color: $alert-success-text;
    background-color: $alert-success;

    a {
      color: $alert-success-text;
    }

    [data-tooltip].has-underline {
      background-image: linear-gradient(90deg, $alert-success-text 33%, transparent 0);
    }
  }

  &.alert-warning {
    color: $alert-warning-text;
    background-color: $alert-warning;

    a {
      color: $alert-warning-text;
    }

    .btn-primary {
      color: $alert-warning-text;
      background: $alert-warning;
      border-color: $alert-warning-text;
    }

    [data-tooltip].has-underline {
      background-image: linear-gradient(90deg, $alert-warning-text 33%, transparent 0);
    }
  }

  &.alert-danger {
    color: $alert-danger-text;
    background-color: $alert-danger;

    a {
      color: $alert-danger-text;
    }

    [data-tooltip].has-underline {
      background-image: linear-gradient(90deg, $alert-danger-text 33%, transparent 0);
    }
  }

  &.alert-promo {
    background-image: linear-gradient(135deg, $seafoam 0, $bluebird 50%, $screample 100%);

    .btn-primary {
      color: $sg-blue;
      background-color: $white;
      border-color: $white;
    }
  }

  &.alert-promo,
  &.alert-verification,
  &.alert-trial {
    color: $white;

    a {
      color: $white;
    }
  }

  &.alert-verification {
    background-color: $alert-verification;

    .btn-primary {
      color: $mantis;
      background-color: $white;
      border-color: $white;
    }

    .step,
    .all-steps {
      position: absolute;
      max-width: 100%;
      margin: 0;
    }

    .step {
      left: 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .all-steps {
      right: 30px;
    }
  }

  &.alert-trial {
    background-color: $alert-trial;

    .btn-primary {
      color: $white;
      background: $slate;
      border-color: $white;
    }
  }

  &.alert-inline {
    z-index: 0;
    align-self: flex-end;
    flex-grow: 1;
    width: 100%;

    p {
      text-align: left;
    }
  }

  &.alert-beta {
    display: inline-block;
    padding: 9px 12px;
    text-align: left;
    background-color: rgba($screample, 0.05);
    border-left: 2px solid $screample;

    p {
      max-width: none;
      margin: 0;
    }
  }

  &.is-hidden,
  &.is-visible {
    position: fixed;
    left: 0;
    width: 100%;
    transition: top 0.5s;
  }

  &.is-hidden {
    top: -60px;
  }

  &.is-visible {
    top: 0 !important;
  }

  &.tiara-offset {
    left: 180px;
    width: calc(100vw - 180px);
  }

  a {
    text-decoration: underline;
  }

  p {
    position: relative;
    display: inline-block;
    max-width: 80%;
    margin: 0 40px;
    font-size: 13px;
    line-height: 20px;
  }

  .sg-icon {
    position: absolute;
    top: 50%;
    left: -40px;
    margin-top: -10px;
    font-size: 20px;

    &.sg-icon-x {
      right: -40px;
      left: auto;
      cursor: pointer;
    }
  }

  .btn {
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: 20px;
  }
}
